type FormElements = HTMLInputElement | HTMLTextAreaElement;

export default class Validator {
  form: HTMLFormElement;
  elements: HTMLFormControlsCollection;
  submit: HTMLButtonElement;
  valid: boolean;
  constructor(form: HTMLFormElement) {
    this.form = form;
    this.elements = form.elements;
    this.submit = form.submit_button;
    this.valid = false;

    this.init();
  }

  init() {
    [...this.elements].forEach(element => {
      const cast_element = (<FormElements>element);

      cast_element.addEventListener('invalid', () => {
        this.inputValid(cast_element);
      });

      cast_element.addEventListener('blur', () => {
        cast_element.classList.add('input');
        this.inputValid(cast_element);
      });
    });

    if (this.submit) {
      this.submit.addEventListener('click', e => {
        e.preventDefault();
        this.formValid();
        this.allValid();

        if (!this.valid){ return; }

        this.form.submit();
      });
    }
  }

  formValid() {
    let cv = this.form.checkValidity();
    this.valid = cv
  }

  inputValid(elm: FormElements) {
    if (elm.type == 'submit') return;

    const label = elm.getAttribute('data-label');
    const error_elm = this.form.querySelector(`[data-name="${elm.name}"]`);

    const validity_state = elm.validity;
    elm.setCustomValidity('');

    if (error_elm) {
      error_elm.classList.remove('show');
      error_elm.textContent = '';
    }

    if (validity_state.valueMissing) { // requiredチェック
      // elm.setCustomValidity(`${label}を入力して下さい`);
      elm.setCustomValidity(`入力をお願いします`);
    } else if (validity_state.typeMismatch) { //type条件に合っているか
      if (elm.type != 'email') return;
      // elm.setCustomValidity(`${label}はemailで入力して下さい`);
      elm.setCustomValidity(`正しいEmail形式で入力をお願いします`);
    } else if (validity_state.tooLong) { // max-lengthチェック
      elm.setCustomValidity(`${label}は${elm.maxLength}文字以内で入力してください`);
    } else if (elm.name.includes('confirm_')) { // 確認用チェック
      const target_name = elm.name.replace('confirm_', '');
      const target_elm = this.form.querySelector(`[name="${target_name}"]`);

      if (!target_elm) return;

      if (elm.value == (<FormElements>target_elm).value) return;

      // elm.setCustomValidity(`${elm.getAttribute('data-label')}と${target_elm.getAttribute('data-label')}が一致しません`);
      elm.setCustomValidity(`${target_elm.getAttribute('data-label')}と一致しません`);
    }

    if (error_elm && !validity_state.valid) {
      error_elm.classList.add('show');
      error_elm.textContent = elm.validationMessage;
    }
  }

  allValid() {
    [...this.elements].forEach(element => {
      const cast_element = (<FormElements>element);
      cast_element.classList.add('input');
      cast_element.checkValidity();
    })
  }
}
