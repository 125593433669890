interface HeaderType {
  openButton: string,
  closeButton: string,
  openClassName: string
}

export default class Header {
  openButton: NodeListOf<HTMLObjectElement>;
  closeButton: NodeListOf<HTMLObjectElement>;
  openClassName: string;
  constructor(init: Partial<HeaderType> = {
    openButton: '.js-header-open',
    closeButton: '.js-header-close',
    openClassName: 'header-menu-open'
  }) {
    this.openButton = document.querySelectorAll(init.openButton || '');
    this.closeButton = document.querySelectorAll(init.closeButton || '');
    this.openClassName = init.openClassName || '';

    this.init()
  }
  init(): void {
    this.openButton.forEach(button => {
      button.addEventListener('click', () => {
        this.open();
      })
    })
    this.closeButton.forEach(button => {
      button.addEventListener('click', () => {
        this.close();
      })
    })
  }
  open(): void {
    document.body.classList.add(this.openClassName);
  }
  close(): void {
    document.body.classList.remove(this.openClassName);
  }
}
