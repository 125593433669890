import Swiper, { Navigation, Autoplay, Pagination, FreeMode } from 'swiper';
import Validator from './module/_validator';
import MicroModal from 'micromodal';

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import moment from 'moment';

import Header from './module/_header';

const BREAKPOINT = 769;

window.addEventListener('DOMContentLoaded', () => {

  new Header();

  new Swiper(".access-swiper", {
    slidesPerView: 1.1,
    spaceBetween: 8,
    centeredSlides: true,
    // pagination: {
    //   el: ".access-swiper-pagination",
    // },
    breakpoints: {
      [BREAKPOINT]: {
        navigation: {
          nextEl: ".access-swiper-next",
          prevEl: ".access-swiper-prev",
        }
      }
    },
    modules: [Navigation, Pagination]
  });

  new Swiper(".school-detail-slider", {
    slidesPerView: 1.3,
    loop: true,
    centeredSlides: true,
    spaceBetween: 12,
    speed: 1000,
    autoplay: {
      delay: 2500
      // waitForTransition: false,
      // disableOnInteraction: false
    },
    navigation: {
      nextEl: ".school-detail-slider-next",
      prevEl: ".school-detail-slider-prev",
    },
    modules: [Navigation, Autoplay]
  });

  new Swiper(".news-slider", {
    slidesPerView: 1.3,
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 2500
      // waitForTransition: false,
      // disableOnInteraction: false
    },
    spaceBetween: 32,
    pagination: {
      el: ".news-slider-pagination",
    },
    navigation: {
      nextEl: ".news-slider-next",
      prevEl: ".news-slider-prev",
    },
    breakpoints: {
      [BREAKPOINT]: {
        slidesPerView: 3.6,
        spaceBetween: 64
      }
    },
    modules: [Navigation, Pagination, Autoplay]
  });

  new Swiper(".book-slider", {
    freeMode: {
      enabled: true,
      sticky: true,
      momentumBounce: false
    },
    width: 130,
    spaceBetween: 18,
    breakpoints: {
      [BREAKPOINT]: {
        freeMode: false,
        width: null,
        slidesPerGroup: 8,
        slidesPerView: 8,
        spaceBetween: 40,
        navigation: {
          nextEl: ".book-slider-next",
          prevEl: ".book-slider-prev",
        }
      }
    },
    modules: [Navigation, FreeMode]
  });

  // SP
  if (window.innerWidth < BREAKPOINT) {
    new Swiper(".review-slider", {
      slidesPerView: 1.3,
      loop: true,
      spaceBetween: 20,
      pagination: {
        el: ".review-slider-pagination",
      },
      modules: [Pagination]
    });
  }
  // PC
  else {
    new Swiper(".review-slider", {
      slidesPerView: 3,
      loop: true,
      spaceBetween: 0,
      pagination: {
        el: ".review-slider-pagination",
      },
      navigation: {
        nextEl: ".review-slider-next",
        prevEl: ".review-slider-prev",
      },
      modules: [Navigation, Pagination]
    });
  }

  new Swiper(".school-swiper", {
    enabled: false,
    breakpoints: {
      [BREAKPOINT]: {
        enabled: true,
        centerInsufficientSlides: true,
        slidesPerView: 3,
        spaceBetween: 16,
      }
    }
  });

  const calendarEl = document.getElementById('calendar');

  if (calendarEl) {
    const calendar = new Calendar(calendarEl, {
      plugins: [
        dayGridPlugin
      ],
      locale: jaLocale,
      validRange: function (nowDate) {
        return {
          start: moment(nowDate).startOf('M').format(),
          end: moment(nowDate).add(1, 'M').endOf('M').format()
        };
      },
      headerToolbar: {
        start: 'title',
        end: 'prev next today'
      },
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
      },
      contentHeight: 'auto'
    });

    calendar.render();

    const start = moment(new Date()).startOf('M').format('YYYY-MM-DD');
    const end = moment(new Date()).add(1, 'M').endOf('M').format('YYYY-MM-DD');

    fetch(`/wp-json/api/v1/calendar?start=${start}&end=${end}`)
      .then(res => {
        if (!res.ok) throw new Error(`${res.url} ${res.statusText}`);
        return res.json();
      })
      .then(data => {

        interface CalendarData {
          title: string,
          start: string,
          end: string
        };

        const json: CalendarData[] = JSON.parse(data);

        if (!Array.isArray(json)) return;

        calendar.batchRendering(function () {
          json.forEach((row: CalendarData) => {
            let class_name = '';

            if (row.title.includes('Monthly')) {
              class_name = 'monthly';
            } else if (row.title.includes('1day')) {
              class_name = 'day';
            } else if (row.title.includes('Online')) {
              class_name = 'online';
            }

            calendar.addEvent({
              title: row.title,
              start: row.start,
              end: row.end,
              allDay: true,
              display: 'block',
              className: class_name
            });
          });
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  //// form
  const forms = document.forms;
  [...forms].forEach(form => {
    if (!form['submit-button']) return;

    (<HTMLButtonElement>form['submit-button']).addEventListener('click', e => {
      e.preventDefault(); // debug
      let v = new Validator(form);
      v.formValid();
      if(v.valid){
        let form_data = new FormData(form);
        let k0 = form.getAttribute('data-form-keys');
        if (k0 !== null) {
          let k = k0.split(',');
          let i = 1;
          for(i=1;i<=k.length;i++){
            let e = document.querySelector(`form input[name=${k[i-1]}]`);
            e ||=  document.querySelector(`form textarea[name=${k[i-1]}]`);
            if (e !== null) {
              let input = document.createElement("input");
              input.setAttribute('type', 'hidden');
              input.setAttribute('name', 'order_' + i);
              input.setAttribute('value', [e.getAttribute('type'), k[i-1], 'false', e.getAttribute('data-label')].join(','));
              form.appendChild(input);
            }
            // order_countが必要(int)
            let input_cnt = document.createElement("input");
            input_cnt.setAttribute('type', 'hidden');
            input_cnt.setAttribute('name', 'order_count');
            input_cnt.setAttribute('value', i.toString());
            form.appendChild(input_cnt);
          }
        }

        let input_js = document.createElement("input");
        input_js.setAttribute('type', 'hidden');
        input_js.setAttribute('name', 'javascript_action');
        input_js.setAttribute('value', 'true');
        form.appendChild(input_js);
        
        fetch(form.action, {
          method: 'POST',
          body: new FormData(form)
        })
          .then(response => response.text())
          .then(txt => {
            console.log(txt);
            let response = txt.split( ',' );
            if ( response[0] === 'send_success' ) {
              location.href = response[1];
            } else {
              Promise.reject(response);
            }
          }).catch(e => {
            alert('送信に失敗しました。再度お試し下さい。');
            console.log(e);
          });
      }
      else {
        location.href = form.getAttribute('data-error-to') || '#';
      }
    });
  });

  // modal
  MicroModal.init();
})
